import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-10.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import mino1 from "../../assets/images/sp/10/mino-1.jpg";
import mino2 from "../../assets/images/sp/10/mino-2.jpg";
import mino3 from "../../assets/images/sp/10/mino-3.jpg";
import mino4 from "../../assets/images/sp/10/mino-4.jpg";
import mino5 from "../../assets/images/sp/10/mino-5.jpg";
import mino6 from "../../assets/images/sp/10/mino-6.jpg";
import mino7 from "../../assets/images/sp/10/mino-7.jpg";
import mino8 from "../../assets/images/sp/10/mino-8.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Mino Caggiula Architects | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 10</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.minocaggiula.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Mino Caggiula Architects</h6>
                                <h2>'Open being'</h2>
                                <h3>è uno stato di consapevolezza propositiva insito nel lavoro interdisciplinare,
                                    frutto di molteplici soluzioni praticabili.</h3>
                                <p>Mino Caggiula Architects nasce nel 2010 con sede a Lugano, da un percorso
                                    professionale che si snoda tra progettazione, design, costruzione, studi e ricerche
                                    teoriche. <br/>
                                    Il team è composto da un gruppo affiatato di architetti con diversi background e
                                    formazioni, che lavorano su progetti che spaziano dall'oggetto di design alla
                                    pianificazione urbana, con particolare attenzione alla sostenibilità, al contesto e
                                    al territorio. <br/>
                                    L'attenzione alla sostenibilità, al contesto e al territorio sono gli elementi
                                    chiave di ogni singolo progetto dell'architetto Mino Caggiula. L'idea principale
                                    nasce dall'osservazione del contesto, cresce attraverso l'uso di innumerevoli
                                    maquette e sboccia in fase di esecuzione. Questa è la trasformazione della forma
                                    d'arte conosciuta come Architettura. <br/>
                                    La carriera di Mino Caggiula inizia in Svizzera, presso l'impresa di costruzioni
                                    Michele Barra di Ascona. Consegue il diploma federale di muratore presso la SPAI di
                                    Biasca nel 1997 e il diploma in Architettura presso la SUPSI di Lugano nel 2001. A
                                    completamento della sua formazione umanistica, ha frequentato l'Accademia di
                                    architettura di Mendrisio, lavorando durante gli studi in vari campi della
                                    costruzione e della progettazione architettonica. <br/>
                                    Dal 2004 al 2005 frequenta la Columbia University di New York con il professore e
                                    architetto Kenneth Frampton. Durante questo periodo ha avuto un'importante
                                    esperienza lavorativa presso lo studio dell'architetto Steven Holl, con il quale ha
                                    vinto numerosi concorsi internazionali. Tra questi, i primi posti per l'Herning
                                    Center of Arts in Danimarca (inaugurato nel 2009), il Museo dell'Oceano e del Surf a
                                    Biarritz, Francia (inaugurato nel 2011) e l'edificio del Linked Hybrid Complex a
                                    Pechino, Cina (inaugurato nel 2009). <br/>
                                    Dal 2005 al 2007 partecipa a numerosi convegni internazionali e consegue il Master
                                    of Arts in Architecture all'Accademia di Mendrisio, presentando un progetto per il
                                    recupero urbano dell'intera Laguna di Venezia con l'architetto Elia Zenghelis. Il
                                    progetto ha ottenuto il primo premio SIA e il primo premio della Regione Veneto.
                                </p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-justify">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={mino1} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 12, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Premi e riconoscimenti</h6>
                                <ul>
                                    <li>Silver award – IDA Design Awards 2020</li>
                                    <li>Vincitore- ARCHITECTURE MASTERPRIZE 2020</li>
                                    <li>Vincitore - Landscape - ARCHITECTURE MASTERPRIZE 2020</li>
                                    <li>Vincitore- THE CHINA YUANYE AWARDS 2020</li>
                                    <li>Platinum award- OPAL Outstanding Propertry Award, London 2020</li>
                                    <li>Menzione d’onore – THE PLAN Awards 2020</li>
                                    <li>Menzione d’onore in architectural design - DESIGN EDUCATES AWARDS 2020</li>
                                    <li>Esposizione Best International Houses Design Selection – CASA PLATFORM 2021</li>
                                    <li>Mino Caggiula ha partecipato a THE PLAN PERSPECTIVE New York City con una
                                        conferenza sulla sostenibilità nel 2019
                                    </li>
                                    <li>Mino Caggiula ha tenuto conferenze come parte di THE PLAN SEMINARS 2020 e 2021
                                    </li>
                                    <li>Mino Caggiula ha partecipato a XI’AN QUJIANG INTERNATIONAL SUMMIT con una
                                        lecture su - Architecture and Next Generation 2021
                                    </li>
                                    <li>Mino Caggiula ha partecipato a YUANYE AWARDS INTERNATIONAL SHARING MEETING come
                                        commentatore e critico d’architettura per 12 progetti selezionati nel 2021
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 12, order: 1}} className="text-justify">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={mino2} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={mino3} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={mino4} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={mino5} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={mino6} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={mino7} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={mino8} alt="Mino Caggiula"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                                <h6>Alcuni dei progetti firmati Mino Caggiula Architects</h6>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="bg-secondary">
                    <div className="container text-center">
                        <h2>Arch NextGen - Mino Caggiula Architects</h2>
                        <div className="youtubeContainer">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/RrKQLk2I0Gw"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
